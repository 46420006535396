<template>
  <li>
    <div
      class="flex flex-col items-center justify-center h-[4.5rem] px-2 py-2 my-4 text-base bg-amber-300 rounded-xl font-normal rounded-lg dark:text-white group"
    >
      <div
        v-show="!collapsed"
        class="flex flex-row justify-between items-center w-full gap-2"
      >
        <div class="flex flex-row gap-1">
          <div
            v-show="
              metrics_store.activeUserDailyGoalStreak != null &&
              metrics_store.activeUserDailyGoalStreak != 0
            "
            class="max-h-9 mr-2"
          >
            <img :src="fireIcon" class="max-h-9" />
            <span
              class="relative rounded-full bg-white text-black p-1 bottom-6 left-[.75rem] text-[.55rem] font-light border-[1px] border-black"
            >
              x{{ metrics_store.activeUserDailyGoalStreak }}
            </span>
          </div>
          <div class="flex flex-col gap-2 pl-1">
            <span class="text-xs text-black h-3.5 w-12 whitespace-nowrap">
              TODAY'S GOAL:
            </span>
            <span
              class="font-bold text-sm text-black h-3.5 w-12 whitespace-nowrap"
            >
              {{ metrics_store.activeUserDailyGoal }} Work Items
            </span>
          </div>
        </div>
        <FlowbiteStatsInfoIcon @click="openStats" />
      </div>
      <div
        v-show="!collapsed"
        class="flex flex-row justify-center items-baseline gap-2 w-full"
      >
        <div class="w-full h-1.5 mb-1.5 bg-blue-900 rounded-full">
          <div
            class="h-1.5 bg-sky-400 rounded-full"
            :style="{
              width: completedRatio >= 100 ? 100 : completedRatio + '%',
            }"
          />
        </div>
        <div class="text-black text-xs">{{ completedRatio }}%</div>
      </div>
      <div v-show="collapsed" class="w-full h-10">
        <CircleProgress
          :percent="completedRatio >= 100 ? 100 : completedRatio"
          fill-color="#14B1E7"
          empty-color="#0F2E44"
          :size="40"
          :border-bg-width="5"
          :border-width="5"
        />
        <div
          class="relative bottom-[31px] right-[5px] text-[10px] text-black w-[50px] h-[10px] text-center flex items-center justify-center"
        >
          <div class="w-full h-full">{{ completedRatio }}%</div>
        </div>
      </div>
    </div>
    <div
      v-show="stats"
      ref="target"
      class="flex flex-col absolute left-[18rem] top-[4rem] w-[28rem] h-40 drop-shadow bg-white rounded-lg text-black justify-evenly px-4"
    >
      <div class="flex flex-row gap-0 px-6 justify-center">
        <div
          class="rounded-l-lg px-4 text-center w-40 cursor-pointer"
          :class="{
            'bg-amber-300': statsTime == statsTimeStates.TODAY,
            'bg-gray-200': statsTime != statsTimeStates.TODAY,
            'font-light': statsTime != statsTimeStates.TODAY,
          }"
          @click="changeStatsTime(statsTimeStates.TODAY)"
        >
          Today
        </div>
        <div
          class="px-4 text-center w-40 cursor-pointer"
          :class="{
            'bg-amber-300': statsTime === statsTimeStates.WEEK,
            'bg-gray-200': statsTime !== statsTimeStates.WEEK,
            'font-light': statsTime != statsTimeStates.WEEK,
          }"
          @click="changeStatsTime(statsTimeStates.WEEK)"
        >
          Last Week
        </div>
        <div
          class="rounded-r-lg px-4 text-center w-40 cursor-pointer"
          :class="{
            'bg-amber-300': statsTime === statsTimeStates.MONTH,
            'bg-gray-200': statsTime !== statsTimeStates.MONTH,
            'font-light': statsTime != statsTimeStates.MONTH,
          }"
          @click="changeStatsTime(statsTimeStates.MONTH)"
        >
          Last Month
        </div>
      </div>
      <div class="flex flex-row gap-6 justify-center">
        <div class="flex flex-col gap-2">
          <div v-show="statsTime === statsTimeStates.TODAY">
            {{ metrics_store.activeUserDailyAssigned }}
          </div>
          <div v-show="statsTime === statsTimeStates.WEEK">
            {{ metrics_store.activeUserWeeklyAssigned }}
          </div>
          <div v-show="statsTime === statsTimeStates.MONTH">
            {{ metrics_store.activeUserMonthlyAssigned }}
          </div>
          <div class="font-thin text-sm">Assigned</div>
        </div>
        <div class="flex flex-col gap-2">
          <div v-show="statsTime === statsTimeStates.TODAY">
            {{ metrics_store.activeUserDailyWip }}
          </div>
          <div v-show="statsTime === statsTimeStates.WEEK">
            {{ metrics_store.activeUserWeeklyWip }}
          </div>
          <div v-show="statsTime === statsTimeStates.MONTH">
            {{ metrics_store.activeUserMonthlyWip }}
          </div>
          <div class="font-thin text-sm">Added to WIP</div>
        </div>
        <div class="flex flex-col gap-2">
          <div v-show="statsTime === statsTimeStates.TODAY">
            {{ metrics_store.activeUserDailyComplete }}
          </div>
          <div v-show="statsTime === statsTimeStates.WEEK">
            {{ metrics_store.activeUserWeeklyComplete }}
          </div>
          <div v-show="statsTime === statsTimeStates.MONTH">
            {{ metrics_store.activeUserMonthlyComplete }}
          </div>
          <div class="font-thin text-sm">Complete</div>
        </div>
        <div class="flex flex-col gap-2">
          <div v-show="statsTime === statsTimeStates.TODAY">
            {{
              formatMilliseconds(metrics_store.activeUserDailyTimeToComplete)
            }}
          </div>
          <div v-show="statsTime === statsTimeStates.WEEK">
            {{
              formatMilliseconds(metrics_store.activeUserWeeklyTimeToComplete)
            }}
          </div>
          <div v-show="statsTime === statsTimeStates.MONTH">
            {{
              formatMilliseconds(metrics_store.activeUserMonthlyTimeToComplete)
            }}
          </div>
          <div class="font-thin text-sm">Time/Work Item</div>
        </div>
      </div>
    </div>
  </li>
</template>
<script setup>
import CircleProgress from 'vue3-circle-progress';
import FlowbiteStatsInfoIcon from '@/assets/sidebar-icons/FlowbiteStatsInfoIcon.vue';
import { ref, onMounted, computed } from 'vue';
import { useMetrics } from '@/stores/useMetrics';
import { onClickOutside } from '@vueuse/core';
import { formatMilliseconds } from '@/utils/helpers';

import fireIcon from '@/assets/fire-icon.svg';
const metrics_store = useMetrics();

const statsTimeStates = Object.freeze({
  TODAY: 0,
  WEEK: 1,
  MONTH: 2,
});

const stats = ref(false);
const statsTime = ref(statsTimeStates.TODAY);
const target = ref(null);

const completedRatio = computed(() => {
  const { activeUserDailyGoal: goal, activeUserDailyComplete: complete } =
    metrics_store;

  if (!goal) return 0;

  return goal === 0 && complete === 0
    ? 100
    : Math.floor((complete * 100) / goal);
});

onClickOutside(target, event => closeStats());

onMounted(async () => {
  updateStatsDetails();
});

function closeStats() {
  if (stats.value) {
    stats.value = false;
  }
}

function openStats() {
  stats.value = true;
}

const updateStatsDetails = function () {
  setTimeout(() => metrics_store.getUserMetrics(), 1000);
};

function changeStatsTime(enumValue) {
  if (
    enumValue != statsTimeStates.TODAY &&
    enumValue != statsTimeStates.WEEK &&
    enumValue != statsTimeStates.MONTH
  )
    return;

  statsTime.value = enumValue;
}
defineProps({
  collapsed: {
    default: false,
    type: Boolean,
  },
});
</script>
