<template>
  <li>
    <div
      class="flex justify-center align-center items-center h-11 p-2 my-4 text-base bg-sky-950 border-[1px] border-sky-500 rounded-xl font-normal rounded-lg cursor-pointer group"
      @click="openSearchModal"
    >
      <FlowbiteSearchIcon class="max-h-4 stroke-white" />
      <span v-show="!collapsed" class="ml-3">Search</span>
    </div>
  </li>
</template>
<script setup>
import { useSearchStore } from '@/stores/useSearch';
import FlowbiteSearchIcon from '@/assets/sidebar-icons/FlowbiteSearchIcon.vue';

const search_store = useSearchStore();

function openSearchModal() {
  search_store.clear();
  search_store.showSearchModal(true);
}

defineProps({
  collapsed: {
    default: false,
    type: Boolean,
  },
});
</script>
